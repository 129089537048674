export default defineNuxtRouteMiddleware((to) => {
  if (process.client) {
    document.querySelector('#app') && (document.querySelector('#app').scrollTop = 0)
  }

  if (to.path !== '/') {
    return
  }

  let currentDomain = ''
  if (process.server) {
    const headers = useRequestHeaders(['host'])
    currentDomain = headers.host || ''
  } else {
    currentDomain = window.location.host
  }
  const route = useRoute()
  const art = route.query.head
  const art1 = route.query.heads
  if(currentDomain == 'cjdxyx.ykumda.com'&&!isNaN(art)){
    return navigateTo('/index')
  }else if(currentDomain == 'cjdxyx.ykumda.com'){
    return navigateTo('/index_hnkj')
  }
  // if (currentDomain == 'cjdxyx.ykumda.com') {
  // // if (currentDomain == '192.168.10.8:3002') {
  //   return navigateTo('/index_hnkj')
  // }

  if(currentDomain == 'aufe.ykumda.com'&& !isNaN(art1)) {
    return navigateTo('/index')
  }else if(currentDomain == 'aufe.ykumda.com'){
    return navigateTo('/index_ahcj')
  }
})
